<template>
  <div class="app-container">
    <div v-html="content"></div>
  </div>


</template>

<script>

import {detailDescribe } from "@/api/describe";

export default {
  name: 'Describe',
  components: {},
  data() {
    return {
      content : "",
    }
  },
  created() {
    this.getDetail()
  },
  methods: {

    getDetail(){
      detailDescribe(1).then(response=>{
        if (response.code === '0') {
          this.content = response.data.content;
        }
      })
    },

  }
}
</script>
