import request from '@/utils/request';

//详情
export function detailDescribe(data) {
  return request({
    url:'/describe/detailDescribe',
    method : 'post',
    data : data
  })
}

//修改
export function updateDescribe(data) {
  return request({
    url :'/describe/updateDescribe',
    method : 'post',
    data : data
  })
}
